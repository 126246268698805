import { Grid, Paper } from '@material-ui/core';
import { DevDocsBreadcrumbs } from './DevDocsBreadcrumbs';
import { DisplayContentPage } from './DisplayContentPage';
import React from 'react';
import { useStyles } from '../styles/styles';

export const Page: React.FC<PageProps> = (props) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <Grid item xs={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                    <DevDocsBreadcrumbs {...props} paths={props.paths} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={props.entry?.misc ? 8 : 12} lg={props.entry?.misc ? 9 : 12}>
                <Paper className={classes.paper}>
                    <DisplayContentPage {...props} type={props.entry?.type} />
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3} hidden={!props.entry?.misc}>
                <Paper className={classes.paper}>
                    <h1>Useful links</h1>
                    {props.entry?.misc?.map((misc, index) => {
                        return (
                            <a
                                href={misc.url}
                                key={index}
                                target="_blank"
                                rel="noreferrer noopener"
                                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                            >
                                {misc.displayName ? misc.displayName : misc.url}
                            </a>
                        );
                    })}
                </Paper>
            </Grid>
        </React.Fragment>
    );
};
