import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import AppMenuItem from './AppMenuItem';

interface Props {
    appMenuItems: Config;
}

const AppMenu: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { appMenuItems } = props;

    return (
        <List component="nav" className={classes.appMenu} disablePadding>
            {appMenuItems.map((item, index) => (
                <AppMenuItem {...item} key={index} />
            ))}
        </List>
    );
};

const drawerWidth = 240;

const useStyles = makeStyles(() =>
    createStyles({
        appMenu: {
            width: '100%',
        },
        navList: {
            width: drawerWidth,
        },
        menuItem: {
            width: drawerWidth,
        },
    }),
);

export default AppMenu;
