import React, { useEffect, useState } from 'react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import App from './components/App';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

Amplify.configure({
    Auth: {
        region: 'eu-west-1',
        identityPoolRegion: 'eu-west-1',
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_WEB_CLIENT_ID,
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
    },
});

Auth.configure({
    oauth: {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
        scope: ['openid'],
        redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_IN,
        responseType: 'code',
    },
});

const CognitoApp: React.FC = () => {
    const [session, setSession] = useState<CognitoUserSession | undefined>();

    Hub.listen('auth', ({ payload: { event, data } }) => {
        switch (event) {
            case 'signIn':
            case 'cognitoHostedUI':
                getSession()
                    .then((session) => setSession(session))
                    .catch(() => {
                        console.log('Not signed in');
                    });
                break;
            case 'signOut':
                setSession(undefined);
                break;
            case 'signIn_failure':
            case 'cognitoHostedUI_failure':
                console.log('Sign in failure', data);
                break;
        }
    });

    useEffect(() => {
        getSession()
            .then((session) => setSession(session))
            .catch(() => {
                Auth.federatedSignIn();
                console.log('Not signed in');
            });
    }, []);

    function getSession() {
        return Auth.currentSession().then((session) => session);
    }

    return <div>{session ? <App /> : <div>Logging in</div>}</div>;
};

export default CognitoApp;
