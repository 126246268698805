import * as React from 'react';
import { Container } from '@material-ui/core';

export const WelcomePage: React.FC<PageProps> = () => {
    return (
        <Container>
            <h1>Welcome to DevDocs</h1>
            <p>DevDocs is a tool for developers @Adaptavist with curated developer documentation.</p>
            <p>
                Check out the{' '}
                <a
                    href={'https://i.adaptavist.com/display/ENG/2020/08/03/This+thing+called+DevDocs'}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    blog
                </a>{' '}
                about it!
            </p>
        </Container>
    );
};
