import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

export const getCurrentSession = (): Promise<CognitoUserSession> => {
    return Auth.currentSession();
};

export const authService = {
    getAccessToken: async (): Promise<string> => {
        const session = await getCurrentSession();
        const accessToken = session.getAccessToken();
        return accessToken.getJwtToken();
    },
};
