import * as React from 'react';
import { useEffect, useState } from 'react';
import API from '../utils/api';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { Container } from '@material-ui/core';
import yaml from 'js-yaml';
import { authService } from '../auth';

const ReactMarkdown = require('react-markdown');

export const DisplayContentPage: React.FC<PageProps> = (props) => {
    const [content, setContent] = useState({});
    const [isBusy, setBusy] = useState(true);

    useEffect(() => {
        const fetchContent = async () => {
            try {
                const token = await authService.getAccessToken();
                const result = await API.get(props.location.pathname || '', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setContent(result.data);
            } catch (error) {
                console.log(error);
                setContent('No Content Available');
            } finally {
                setBusy(false);
            }
        };

        fetchContent();
    }, [props.location.pathname]);

    const renderSwitch = (type = '') => {
        switch (type) {
            case 'swagger':
                return <SwaggerUI spec={yaml.load(content.toString())} />;
            case 'html':
                return <div dangerouslySetInnerHTML={{ __html: content.toString() }} />;
            default:
                return <ReactMarkdown source={content.toString()} />;
        }
    };

    return <Container>{!isBusy ? renderSwitch(props.type) : <CircularProgress />}</Container>;
};
