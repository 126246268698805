import React, { useEffect, useState } from 'react';
import { Breadcrumbs } from '@material-ui/core';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useStyles } from '../styles/styles';

export const DevDocsBreadcrumbs: React.FC<PageProps> = (props) => {
    const location = useLocation();
    const classes = useStyles();
    const [paths, setPaths] = useState<string[]>([]);

    useEffect(() => {
        const constructPaths = () => {
            const splitPath = location.pathname.split('/');
            const endPaths = [''];
            if (splitPath.length >= 2) {
                for (let i = 1; i <= splitPath.length - 1; i++) {
                    endPaths.push(endPaths[i - 1] + '/' + splitPath[i]);
                }
            }
            setPaths(endPaths.filter((path) => path !== '/').filter((path) => path !== ''));
        };

        constructPaths();
    }, [location]);

    const breadcrumbs = paths.map((path, index) => {
        const name = props.paths?.find((p) => p.path === path)?.name;
        return (
            <NavLink
                color="inherit"
                to={path}
                key={index}
                className={classes.linkNone}
                style={{ color: 'rgba(0, 0, 0, 0.54)' }}
            >
                {name}
            </NavLink>
        );
    });

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link to={'/'} key={-1} className={classes.linkNone} style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                Home
            </Link>
            {breadcrumbs}
        </Breadcrumbs>
    );
};
