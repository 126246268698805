import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Switch, Route, BrowserRouter, Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import AppMenu from './AppMenu';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import IconButton from '@material-ui/core/IconButton/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar/AppBar';
import { useStyles } from '../styles/styles';
import Divider from '@material-ui/core/Divider/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import API from '../utils/api';
import IconLibraryBooks from '@material-ui/icons/LibraryBooks';
import Box from '@material-ui/core/Box/Box';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { authService } from '../auth';
import { CircularProgress, Grid, Paper } from '@material-ui/core';
import { WelcomePage } from './WelcomePage';
import useTheme from '@material-ui/core/styles/useTheme';
import { ThemeProvider } from '@material-ui/core/styles';
import { DevDocsBreadcrumbs } from './DevDocsBreadcrumbs';
import { Page } from './Page';

const App: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(true);
    const [config, setConfig] = useState<Config>([]);
    const [paths, setPaths] = useState<Paths>([]);
    const [isBusy, setIsBusy] = useState(true);

    useEffect(() => {
        const fetchConfig = async () => {
            const token = await authService.getAccessToken();
            const result = await API.get('config', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setConfig(result.data.config);
            setPaths(result.data.paths);
            setIsBusy(false);
        };

        fetchConfig();
    }, []);

    const appMenuItems: Config = config.map((cfg) => ({
        name: cfg.name,
        Icon: IconLibraryBooks,
        items: cfg.items,
        link: cfg.link,
    }));

    const routes = paths.map((entry, index) => (
        <Route
            exact
            key={index}
            path={entry.path}
            render={(props) => <Page {...props} paths={paths} entry={entry} />}
        />
    ));

    routes.push(
        <Route
            exact
            key={'root'}
            path={'/'}
            render={(props) => (
                <React.Fragment>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <DevDocsBreadcrumbs {...props} paths={paths} />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Paper className={classes.paper}>
                            <WelcomePage {...props} />
                        </Paper>
                    </Grid>
                </React.Fragment>
            )}
        />,
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <ThemeProvider theme={useTheme()}>
            <div>
                {isBusy ? (
                    <CircularProgress className={classes.marginAutoItem} />
                ) : (
                    <BrowserRouter>
                        <div className={clsx('App', classes.root)}>
                            <CssBaseline />
                            <AppBar
                                position="fixed"
                                className={clsx(classes.appBar, {
                                    [classes.appBarShift]: open,
                                })}
                            >
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleDrawerOpen}
                                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Link
                                        to={'/'}
                                        className={classes.title}
                                        style={{ color: 'white', textDecoration: 'none' }}
                                    >
                                        <Typography variant="h6" noWrap>
                                            DevDocs
                                        </Typography>
                                    </Link>
                                </Toolbar>
                            </AppBar>
                            <Drawer
                                variant="permanent"
                                classes={{
                                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                                }}
                                open={open}
                            >
                                <div className={classes.toolbarIcon}>
                                    <IconButton onClick={handleDrawerClose}>
                                        <ChevronLeftIcon />
                                    </IconButton>
                                </div>
                                <Divider />
                                <AppMenu appMenuItems={appMenuItems} />
                            </Drawer>
                            <main className={classes.content}>
                                <div className={classes.appBarSpacer} />
                                <Container maxWidth="lg" className={classes.container}>
                                    <Grid container spacing={3}>
                                        <Switch>{routes}</Switch>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <Box pt={4}>
                                                <Typography variant="body1" color="textSecondary" align="center">
                                                    {`Built with `}
                                                    <FavoriteIcon color={'secondary'} />
                                                    {` by the Shared Services Team ${new Date().getFullYear()}.`}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Container>
                            </main>
                        </div>
                    </BrowserRouter>
                )}
            </div>
        </ThemeProvider>
    );
};

export default App;
